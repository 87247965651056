import React from 'react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFade } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import images from "../../constants/images.js";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade';
import 'swiper/css/bundle';
import './carousel.css';


export function CarouselBanner() {

    const data = [
        { id: '1', image: images.banner1 },
        { id: '2', image: images.banner2 },
        { id: '3', image: images.banner5 },
        { id: '4', image: images.banner3 },
        { id: '5', image: images.banner4 },
    ]

    return (
        <div className='containerCarousel'>
            <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFade]}
                // spaceBetween={1}
                effect="fade"
                slidesPerView={1}
                navigation
                pagination={{ clickable: true }}
                autoplay={{delay: 7000}}
                loop
                // scrollbar={{ draggable: true }}
            >
            {data.map((item) => (
                <SwiperSlide key={item.id}>
                    <img
                        src={item.image}
                        alt="Slider ELETEC - So portoes e portas Automaticas"
                        className='slide-item'
                    />
                </SwiperSlide>
            ))}
        </Swiper>
        </div >
    )
}