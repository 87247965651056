import React from 'react';
import "./App.css";
import { Header } from './components/Header'
import { Banner } from './components/Banner'
import { Section1 } from './components/Section1'
import { Section2 } from './components/Section2'
import { Section3 } from './components/Section3'
import { Contact } from './components/SectionContact'
import { Rodape } from './components/Rodape'

function App() {

    return (
        <div>
            <Header />
            <Banner />
            <Section1 />
            <Section2 />
            <Section3 />
            <Contact />
            <Rodape />
        </div>
    );
}

export default App;
