import React from "react";
import './section3.css';
import images from "../../constants/images.js";

export function Section3() {
    return (
        <div className="section3_all">
            <div className="">
                <h1 className="tituloInst publicsans-light">Instalação</h1>
            </div>
            <div className="coisasInst">
                <div className="descInst">
                    <h1>
                        • A Instalação correta de motores automatizadores é essencial para garantir que portas e portões funcionem de maneira eficiente e segura.
                    </h1>
                    <h1>
                        • Verificamos o local e quais procedimentos serão necessários para uma Instalação segura e perfeita.
                    </h1>
                    <h1>
                        • Nosso técnico altamente qualificado realiza inspeções detalhadas, necessários para evitar falhas e prolongar a vida útil dos equipamentos.
                    </h1>
                </div>
                <div className="linhaVertical"> </div>
                <div className="descInst">
                    <h1>
                        • Fazemos á Venda do Produto e a Instalação separados caso o cliente queira.
                    </h1>
                    <h1>
                        • Vendas e Instalação com nota e garantia de fábrica.
                    </h1>
                    <h1>
                        • Atendemos a região de Curitiba, região metropolitana de Curitiba & ao Brasil todo *Preços a combinar variando a distância*.
                    </h1>
                </div>
            </div>
            <div className="coisasInst">
                <div className="descInst2">
                    <img className="imageinst" src={images.instalacao} alt="Todos os Produtos no site está a venda." />
                    <h1>
                        Agende sua Instalação agora e assegure o perfeito funcionamento de seus motores automatizadores!
                    </h1>
                    <a href="https://wa.link/5z2u3n" className="myButton">
                        <img src={images.wpp} alt="wpp" className="wpp__img" />
                        <p className="roboto-bold">(41) 99656-3104 ~ Osvaldo</p>
                    </a>
                </div>
            </div>
            <div id="section4"></div>
        </div>
    )
}