import React, { useState } from "react";
import './header.css';
import images from "../../constants/images.js";

export function Header() {
    const scrollToSection = (id) => {
        document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
    };

    const [isOpen, setIsOpen] = useState(false);

    // const toggleMenu = () => {
    //     setIsOpen((open) => !open)
    // };


    return (
        <div className="navbar__main">
            <a href="index.html" alt="" className="navbar__brand" >
                <img src={images.logo2} alt="Logo Marca ELETEC - So portoes e portas Automaticas" />
            </a>
            <nav className="navbar">
                <div className="menuDesktop">
                    <button onClick={() => scrollToSection('section1')} href="index.html" className="publicsans-thin">Produtos</button>
                    <button onClick={() => scrollToSection('section2')} href="index.html" className="publicsans-thin">Manutenção</button>
                    <button onClick={() => scrollToSection('section3')} href="index.html" className="publicsans-thin">Instalação</button>
                    <button onClick={() => scrollToSection('section4')} href="index.html" className="publicsans-thin">Contato</button>
                </div>
            </nav>
            <div className="wpp__number">
                <img src={images.wpp} alt="wpp" className="wpp__img" />
                <p className="roboto-bold">(41)99656-3104 ~ Osvaldo</p>
            </div>
            <a href="https://api.whatsapp.com/send?phone=5541996563104" className="floatWpp">
                <img src={images.wpp} alt="wpp2" className="wpp2__img" />
            </a>
            <div className="menuBotao">
                <button onClick={() => setIsOpen(!isOpen)}><img src={images.botaoToggle} alt="imagem botao" className="imgBotao" /></button>
            </div>
            {
                isOpen ?
                    <div className="menuMobile">
                        <button onClick={() => scrollToSection('section1')} href="index.html" className="publicsans-thin">Produtos</button>
                        <button onClick={() => scrollToSection('section2')} href="index.html" className="publicsans-thin">Manutenção</button>
                        <button onClick={() => scrollToSection('section3')} href="index.html" className="publicsans-thin">Instalação</button>
                        <button onClick={() => scrollToSection('section4')} href="index.html" className="publicsans-thin">Contato</button>
                    </div> : null
            }
        </div>
    )
}
