import React from "react";
import './rodape.css';
import images from "../../constants/images.js";


export function Rodape() {
    return (
        <div className="rodapeAll">
            <div className="r1">
                <img className="logoRodape" src={images.logo} alt="ELETEC - So portoes e portas Automaticas" />

            </div>
            <h1>© 2024</h1>
        </div>
    )
}