import React from "react";
import './section1.css';
import images from "../../constants/images.js";

export function Section1() {

    return (
        <div  className="section1__all">
            <div className="espacoTitutlo">
                <h1 className="titulo__principal">Vendas, Instalação & Manutenção.</h1>
                <h2 className="titulo2">Preços e Valores somente via E-mail ou Whatsapp*</h2>
            </div>
            <div className="section1__main">
                <div className="section1_row">
                    <div className="section1__produtos">
                        <h2 className="titulo__produto">Automatizadores para Portas</h2>
                        <p className="desc__produto">• Porta Deslizante, Pivoltante & Batente.</p>
                        <p className="desc__produto">• Empresas, shoppings, lojas, escritórios, residências e comércio em geral.</p>
                        <p className="desc__produto">• Sensor de movimento, Botão de pressão, Controle remoto & Acesso por cartão ou código.</p>
                        <p className="desc__produto">• Sensores de segurança, Fechaduras eletromagnéticas, Baterias de reserva.</p>
                        <p className="desc__produto">• Materiais resistentes, Fácil manutenção.</p>
                        <p className="desc__produto">• Automatizadores PPA & FASS.</p>
                    </div>
                    <div className="section1__produtos__img">
                        <img src={images.porta1} alt="Automatizadores para Portas Automaticas - ELETEC - So portoes e portas Automaticas" className="img__produto" />
                    </div>
                </div>
                <div className="section1_row">
                    <div className="section1__produtos">
                        <h2 className="titulo__produto">Automatizadores Deslizantes</h2>
                        <p className="desc__produto">• Industrias, condominios, residências, comércio em geral e etc...</p>
                        <p className="desc__produto">• Sensores de Segurança & Resistente às Intempéries.</p>
                        <p className="desc__produto">• Durabilidade e Confiabilidade</p>
                        <p className="desc__produto">• Controle Remoto para maior conveniência.</p>
                    </div>
                    <div className="section1__produtos__img">
                        <img src={images.deslizante1} alt="Automatizadores para Portoes Automaticos - ELETEC - So portoes e portas Automaticas, motor portão" className="img__produto" />
                    </div>
                </div>
                <div className="section1_row">
                    <div className="section1__produtos">
                        <h2 className="titulo__produto">Automatizadores Pivotantes</h2>
                        <p className="desc__produto">• Industrias, condominios, residências, comércio em geral e etc...</p>
                        <p className="desc__produto">• Angulação Ajustável & Velocidade Controlada.</p>
                        <p className="desc__produto">• Sensores de Obstrução, Travas de Segurança & Parada de Emergência.</p>
                        <p className="desc__produto">• Materiais de Alta Qualidade, Resistência às Intempéries.</p>
                        <p className="desc__produto">• Manutenção Simples.</p>
                    </div>
                    <div className="section1__produtos__img">
                        <img src={images.pivo1} alt="Automatizadores para Portoes Pivotantes - ELETEC - So portoes e portas Automaticas" className="img__produto" />
                    </div>
                </div>
                <div className="section1_row">
                    <div className="section1__produtos">
                        <h2 className="titulo__produto">Automatizadores Basculantes</h2>
                        <p className="desc__produto">• Os automatizadores basculantes são a solução ideal para quem busca segurança, conveniência e eficiência na automação de portas de garagem basculantes. Projetados para uso residencial e comercial, nossos automatizadores combinam tecnologia de ponta com durabilidade excepcional, garantindo um desempenho confiável e silencioso.</p>
                    </div>
                    <div className="section1__produtos__img">
                        <img src={images.basculante1} alt="Automatizadores para Portoes Basculantes - ELETEC - So portoes e portas Automaticas" className="img__produto" />
                    </div>
                </div>
                <div className="section1_row">
                    <div className="section1__produtos">
                        <h2 className="titulo__produto">Cancelas Automáticas</h2>
                        <p className="desc__produto">• As cancelas automáticas são essenciais para o controle eficiente de acesso em estacionamentos, condomínios, áreas industriais e comerciais. Nossas cancelas são projetadas com tecnologia avançada para proporcionar segurança, confiabilidade e facilidade de uso, garantindo um fluxo de tráfego organizado e controlado.</p>
                    </div>
                    <div className="section1__produtos__img">
                        <img src={images.cancela1} alt="Cancelas Automaticas - ELETEC - So portoes e portas Automaticas" className="img__produto" />
                    </div>
                </div>
                <div className="section1_row">
                    <div className="section1__produtos">
                        <h2 className="titulo__produto">Outros Produtos</h2>
                        <p className="desc__produto">• Automatizadores para Porta de Enrolar</p>
                        <p className="desc__produto">• Centrais de Comando</p>
                        <p className="desc__produto">• Sistemas de Alarme & Sensores</p>
                        <p className="desc__produto">• Interfones</p>
                        <p className="desc__produto">• Cameras</p>
                        <p className="desc__produto">• Travas & Fechaduras</p>
                        <p className="desc__produto">• Eletrificadores de Cerca</p>
                        <p className="desc__produto">• Controles de Acesso</p>
                        <p className="desc__produto">• E outros.</p>
                    </div>
                    <div className="section1__produtos__img">
                        <img src={images.eletreficador1} alt="Automatizadores para Porta de Enrolar, Centrais de Comando, Sistemas de Alarme & Sensores, Interfones, Cameras, Travas & Fechaduras, Eletrificadores de Cerca, Cerca Eletrica, Controles de Acesso - ELETEC - So portoes e portas Automaticas" className="img__produto" />
                    </div>
                </div>
            </div>
            <div id="section2"></div>
        </div>
    )
}