import React, { useState } from "react";
import './contact.css';
import images from "../../constants/images.js";
import emailjs from '@emailjs/browser'

export function Contact() {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const [telefone, setTelefone] = useState('')
    const [assunto, setAssunto] = useState('')

    function sendEmail(e) {
        e.preventDefault();

        if (name === '' || email === '' || message === '' || telefone === '' || assunto === '') {
            alert("Preencha todos os campos")
            return;
        }

        const templateParams = {
            from_name: name,
            message: message,
            email: email,
            subject: assunto,
            telefone: telefone
        }

        emailjs.send('service_cddoosl', 'template_sfaw6fo', templateParams, '4Z97USX9HBq7JfXoU')
            .then((response) => {
                console.log("Email Enviado", response.status, response.text)
                setName('')
                setEmail('')
                setMessage('')
                setTelefone('')
                setAssunto('')
            }, (err) => {
                console.log("ERRO: ", err)
            })
    }

    return (
        <div className="contatoAll">
            <h1 className="tituloCont publicsans-light">Contato</h1>
            <div className="c1">
                <div className="c2">
                    <h1>E-mail para contato</h1>
                    <h2>eleteccuritiba@outlook.com</h2>
                </div>
                <div className="linhaVertical2"></div>
                <div className="c2">
                    <h1>Telefone para contato</h1>
                    <h2>(41) 99956-3104 ~ Falar com Osvaldo</h2>
                </div>
            </div>
            <div className="c1">
                <a href="https://www.instagram.com/osvaldo_turatto" className="instaOsvaldo" ><img src={images.instagram} alt="" />Osvaldo Turatto</a>
            </div>
            <div className="contatoContainer">
                <div className="formularioContainer">
                    <h3>Entre em contato com nossa empresa e em breve responderemos suas dúvidas diretamente em seu e-mail. Obrigado.</h3>
                    <form className="form" onSubmit={sendEmail}>
                        <label htmlFor="nome">Nome:</label>
                        <input
                            className="input"
                            type="text"
                            placeholder="Digite seu Nome"
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                            required
                        />
                        <label htmlFor="telefone">Telefone:</label>
                        <input
                            className="input"
                            type="text"
                            placeholder="Digite seu Telefone"
                            onChange={(e) => setTelefone(e.target.value)}
                            value={telefone}
                            required
                        />
                        <label htmlFor="email">E-mail:</label>
                        <input
                            className="input"
                            type="text"
                            placeholder="Digite seu E-mail"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            required
                        />
                        <label htmlFor="assunto">Assunto:</label>
                        <input
                            className="input"
                            type="text"
                            placeholder="Assunto da mensagem"
                            onChange={(e) => setAssunto(e.target.value)}
                            value={assunto}
                            name="assunto"
                            required
                        />


                        <label htmlFor="mensagem">Mensagem:</label>
                        <textarea
                            type="textarea"
                            placeholder="Digite sua mensagem..."
                            onChange={(e) => setMessage(e.target.value)}
                            value={message}
                            required
                        />
                        <input className="button" type="submit" value="Enviar" />
                    </form>
                </div>
            </div>
        </div>
    )
}