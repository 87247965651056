import React from "react";
import './banner.css';
import images from "../../constants/images.js";
import { CarouselBanner } from "../CarouselBanner/carousel.jsx";

export function Banner() {
    return (
        <div className="banner__main">
            <div className="divCarousel">
                <CarouselBanner />
            </div>
            <div className="banner__second">
                <h1 className="publicsans-thin">Revendedora Autorizada</h1>
                <img src={images.ppa} alt="" className="ppa__banner" />
                <div id="section1"></div>
            </div>
        </div>
    )
}

