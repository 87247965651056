import logo from '../assets/images/logo.png'
import logo2 from '../assets/images/logo2.png'
import wpp from '../assets/images/whatsapp.png'
import wpp2 from '../assets/images/whatsapp2.png'
import banner1 from '../assets/images/banner1.png'
import banner2 from '../assets/images/banner2.png'
import banner3 from '../assets/images/banner3.png'
import banner4 from '../assets/images/banner4.png'
import banner5 from '../assets/images/banner5.png'
import bannercel from '../assets/images/bannercel.png'
import ppa from '../assets/images/ppa.png'
import porta1 from '../assets/images/porta1auto.png'
import deslizante1 from '../assets/images/deslizante1.png'
import pivo1 from '../assets/images/pivo1.png'
import basculante1 from '../assets/images/basculante1.png'
import cancela1 from '../assets/images/cancela1.png'
import eletreficador1 from '../assets/images/eletreficador1.png'
import brasilmap from '../assets/images/brasilmap.png'
import instalacao from '../assets/images/instalacao.png'
import botaoToggle from '../assets/images/botaoToggle.png'
import instagram from '../assets/images/instagram.png'



export default {logo, logo2, wpp, wpp2, banner1, banner2, banner3, banner4,banner5, bannercel, ppa, porta1, deslizante1, pivo1, basculante1, cancela1, eletreficador1, brasilmap, instalacao, botaoToggle, instagram};

