import React from "react";
import './section2.css';
import images from "../../constants/images.js";

export function Section2() {
    return (
        <div className="section2_all">
            <div className="bannerManu">
                <p className="tituloManu publicsans-light">Manutenção</p>
            </div>
            <div className="coisasManu">
                <div className="descManu">
                    <h1>
                        • A manutenção de motores automatizadores é essencial para garantir que portas e portões funcionem de maneira eficiente e segura.
                    </h1>
                    <h1>
                        • Oferecemos uma gama completa de serviços de manutenção preventiva e corretiva, adaptados às necessidades específicas de cada cliente.
                    </h1>
                    <h1>
                        • Nosso técnico altamente qualificado realiza inspeções detalhadas, ajustes precisos e reparos necessários para evitar falhas e prolongar a vida útil dos equipamentos.
                    </h1>
                </div>
                <div className="linhaVertical"> </div>
                <div className="descManu">
                    <h1>
                        • Inspeções periódicas, ajustes, lubrificação, limpeza e verificação de componentes eletrônicos e mecânicos.
                    </h1>
                    <h1>
                        • Inspeção visual, testes funcionais, verificação de conexões elétricas, ajuste de tensionamento de correntes e correias, entre outros.
                    </h1>
                    <h1>
                        • Atendemos a região de Curitiba, região metropolitana de Curitiba & ao Brasil todo *Preços a combinar variando a distância*. 
                    </h1>
                </div>
            </div>
            <div className="coisasManu">
                <div className="descManu2">
                    <img src={images.brasilmap} alt="Atendemos ao Territorio Brasileiro inteiro." className="imgDescManu"/>
                    <h1>
                        Agende sua manutenção agora e assegure o perfeito funcionamento de seus motores automatizadores!
                    </h1>
                    <a href="https://wa.link/5z2u3n" className="myButton">
                        <img src={images.wpp} alt="wpp" className="wpp__img" />
                        <p className="roboto-bold">(41) 99656-3104 ~ Osvaldo</p>
                    </a>
                </div>
            </div>
            <div id="section3"></div>
        </div>
    )
}